export const sourceflowEnums = {
  config: {
    baseUrl: "https://www.gravitasgroup.com",
    siteName: "Gravitas Recruitment Group",
    socialLogo:
      "https://media.licdn.com/dms/image/C4D0BAQHN9687UaD-WQ/company-logo_200_200/0/1630579047955/gravitas_recruitment_group_uk_logo?e=2147483647&v=beta&t=y-gvCgEAM8WC-AzexuKI8iNgiJFtdab_W-IPJabbacA",
    recruitmentTypes: {
      contract: "contract",
      permanent: "permanent",
      array: ["contract", "permanent"],
    },
  },
  forms: {
    register: {
      internal: "ca893e4d-f412-4ead-9721-4acead0b9ec4",
      external: "6702f6e8-14b1-4444-8ec3-9e3d26784d2d",
      homeBanner: "fb6f4f97-dcea-4dea-a41f-f27f68d8626c",
    },
    contactUs: "1a019795-67c5-4de8-9d3a-ecf22f76e2bd",
    eventSignup: "3065c769-f359-4262-ada4-13af80479dc7",
  },
  categories: {
    locations: {
      id: "28cb6906-55c2-4a5e-82b8-f07ea8dbc023",
      uk: "2880afff-e732-4ebe-ace4-3027835fdba3",
      nl: "a2a652dc-27a6-48b3-98be-78fdcac58e3c",
      de: "7a297e1b-56f0-4864-9bd5-473555a5da90",
      hk: "f3018a41-7c36-4c10-87b8-da0f29322929",
      sg: "f27d6208-446d-4d1c-b009-7eb100fe9aa6",
    },
    officeLocations: {
      id: "c05f7e11-7cc9-4332-ac0c-cd7acaef7306",
    },
    sectors: {
      id: "f656755b-20ab-4683-a8c5-6814b4ab25d3",
      internal: "b1aee252-2698-4235-a7d0-361ccfe3dede",
      external: "7830d73a-4057-4883-ad8d-a9e56add479c",
    },
    specialisms: {
      id: "cbc9f6f0-ddc7-45ea-a651-b66e50268e14",
    },
    type: {
      id: "ae5379a3-e474-46da-902f-b37ac544ad60",
      contract: "1bfb8725-de31-463b-872b-753f7a25103e",
      permanent: "a166486e-efea-4866-9821-d27289245c15",
      both: "343dd063-41b9-4101-9c13-de87af2c6a87",
    },
    working_environment: {
      id: "b3377b2d-eecc-40f7-8c8f-a3dc407e79a0",
      any: "abc4847d-7e53-444a-8d57-fa8eba45e215",
      hybrid: "01642ac6-6603-4a92-a674-145bc854299e",
      onsite: "c682ab1a-5cbf-4cdd-a0c3-f8f308fafc6b",
      remote: "323313ee-7ba3-42ae-b422-c60cba542306",
    },
  },
};

export default sourceflowEnums;
